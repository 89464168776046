"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Accordions = function Accordions() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables.
   * @private
   */
  var $accordions = document.querySelectorAll('[data-accordion]');
  var $body = document.querySelector('body');
  var $overlay = document.querySelector('.overlay');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    $accordions.forEach(function ($accordion) {
      var needsBodyLock = $accordion.classList.contains('needs-body-lock');
      var canCloseOutside = $accordion.classList.contains('can-close-outside');
      var accordionTrigger = $accordion.querySelector('[data-accordion-trigger]');
      if (accordionTrigger) {
        accordionTrigger.addEventListener('click', function (e) {
          e.preventDefault();
          $accordion.classList.toggle('is-active');
          if (needsBodyLock) {
            $body.classList.toggle('is-locked');
          }
          if (canCloseOutside && $overlay) {
            $overlay.classList.toggle('is-active');
            $overlay.addEventListener('click', function (e) {
              e.preventDefault();
              $overlay.classList.remove('is-active');
              $accordion.classList.remove('is-active');
              if (needsBodyLock) {
                $body.classList.remove('is-locked');
              }
            });
          }
        });
      }
    });
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();