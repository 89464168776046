"use strict";

window.site = window.site || {};

/**
 * Flickity Carousel for homepage cities
 * @class Carousel
 * @static
 */
site.Carousel = function Carousel() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $carousel = document.querySelector(".flickity-slides");

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($carousel) {
      var utils = window.fizzyUIUtils;
      var flkty = new Flickity($carousel, {
        cellAlign: 'left',
        prevNextButtons: false,
        pageDots: false,
        contain: true,
        lazyLoad: true,
        wrapAround: true
      });

      // elements
      var cellsButtonGroup = document.querySelector('.carousel-pager-nav');
      var cellsButtons = utils.makeArray(cellsButtonGroup.children);

      // update buttons on select
      flkty.on('select', function () {
        var previousSelectedButton = cellsButtonGroup.querySelector('.is-active');
        var selectedButton = cellsButtonGroup.children[flkty.selectedIndex];
        previousSelectedButton.classList.remove('is-active');
        selectedButton.classList.add('is-active');
      });

      // cell select
      cellsButtonGroup.addEventListener('click', function (event) {
        if (!matchesSelector(event.target, '.carousel-pager')) {
          return;
        }
        var index = cellsButtons.indexOf(event.target);
        flkty.select(index);
      });

      // previous
      var previousButton = document.querySelector('.carousel-arrow-left');
      previousButton.addEventListener('click', function () {
        flkty.previous();
      });
      // next
      var nextButton = document.querySelector('.carousel-arrow-right');
      nextButton.addEventListener('click', function () {
        flkty.next();
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();