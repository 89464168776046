"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Footer = function Footer() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Globals elements
   * @private
   */
  var $footerCities = document.querySelector('.footer-cities');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($footerCities) {
      var $footerCitiesTrigger = $footerCities.querySelector('.footer-cities-title');
      var $footerCitiesDropdown = $footerCities.querySelector('.footer-cities-dropdown');
      if ($footerCitiesTrigger && $footerCitiesDropdown) {
        $footerCitiesTrigger.addEventListener('click', function () {
          $footerCitiesDropdown.classList.toggle('is-active');
        });
      }
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();