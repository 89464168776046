"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.ScrollTo = function ScrollTo() {
  'use strict';

  /**
   * Globals elements
   * @private
   */
  var $scrollLinks = [].slice.call(document.querySelectorAll('[data-scroll-to]'));

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    $scrollLinks.forEach(function (item) {
      item.addEventListener('click', function (e) {
        e.preventDefault();
        scrollTo(item.getAttribute('data-scroll-to'));
        document.querySelector('.mobile-menu').classList.remove('is-active');
        document.querySelector('body').classList.remove('is-locked');
        document.querySelector('.header').classList.remove('is-hidden');
      });
    });
    if (window.location.hash) {
      setTimeout(function () {
        scrollTo(window.location.hash.substring(1));
      }, "500");
    }
    return true;
  };

  /**
   * Scroll to a specific element
   * @private
   * @param {string} selector - The selector of the element to scroll to
   */
  var scrollTo = function scrollTo(selector) {
    var $zone = document.getElementById(selector);
    if ($zone) {
      var topPos = $zone.getBoundingClientRect().top + window.scrollY - 210;
      console.log(topPos);
      window.scroll({
        top: topPos,
        behavior: "smooth"
      });
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();