"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.FAQ = function FAQ() {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $faqHeaders = document.querySelectorAll('[data-faq-header]');
  var $faqSelectors = document.querySelectorAll('.faq-mobile-title');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($faqHeaders) {
      $faqHeaders.forEach(function ($faqHeader) {
        $faqHeader.addEventListener('click', function (e) {
          e.preventDefault();
          var currentType = $faqHeader.dataset.faqTab;
          document.querySelectorAll('.faq-container').forEach(function (i) {
            return i.classList.remove('is-open');
          });
          document.querySelector('[data-faq-type="' + currentType + '"]').classList.add('is-open');
          $faqHeaders.forEach(function (i) {
            return i.classList.remove('is-active');
          });
          $faqHeader.classList.add('is-active');
        });
      });
    }
    if ($faqSelectors) {
      $faqSelectors.forEach(function ($faqSelector) {
        $faqSelector.addEventListener('click', function (e) {
          var currentTitle = $faqSelector.dataset.faqTitle;
          var currentSlug = $faqSelector.dataset.faqSlug;
          document.querySelector('[data-faq-selected]').textContent = currentTitle;
          document.querySelectorAll('.faq-container').forEach(function (i) {
            return i.classList.remove('is-open');
          });
          document.querySelector('[data-faq-type="' + currentSlug + '"]').classList.add('is-open');
          document.querySelector('.faq-tabs-mobile').classList.remove('is-active');
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();