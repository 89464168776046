"use strict";

window.site = window.site || {};

/**
 * Utilities for Newsletter
 * @class Track
 * @static
 */
site.Newsletter = function Newsletter() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables
   * @private
   */
  var $newsletterForm = document.querySelector('.footer-newsletter-form');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($newsletterForm) {
      $newsletterForm.addEventListener('submit', function (e) {
        e.preventDefault();
        var formData = new FormData();
        var csrfName = site.App.config('csrfName');
        var csrfToken = $newsletterForm.querySelector("input[name='" + csrfName + "']").value;
        var email = $newsletterForm.querySelector("input[name='email']").value;
        var groupId = $newsletterForm.querySelector("input[name='groupId']").value;
        var $errorField = document.querySelector('.newsletter-error');
        var newsletterAlerts = ['You have subscribed to the newsletter successfully!', 'You have already subscribed to the newsletter.', 'You have already subscribed to the newsletter, please validate your email via the activation email.', 'The Email Address field is mandatory'];
        formData.append(csrfName, csrfToken);
        formData.append('email', email);
        formData.append('groupId', groupId);
        document.querySelector('.newsletter-success').classList.remove('is-visible');
        $errorField.classList.remove('is-visible');
        if (site.App.config('locale') == 'fr') {
          newsletterAlerts = ['Vous êtes bien inscrits à l\'infolettre!', 'Vous vous êtes déjà inscrits à l\'infolettre.', 'Vous vous êtes déjà inscrits à l\'infolettre, veuillez valider votre courriel via le courriel d\'activation.', 'Le champ Adresse courriel est obligatoire'];
        }
        if (!email) {
          $errorField.classList.add('is-visible');
          $errorField.textContent = newsletterAlerts[3];
          return false;
        }
        axios({
          method: "POST",
          url: "/site/subscribeNewsletter",
          data: formData
        }).then(function (response) {
          if (response['data'] == 'exists') {
            $errorField.classList.add('is-visible');
            $errorField.textContent = newsletterAlerts[1];
          } else if (response['data'] == 'unconfirmed') {
            $errorField.classList.add('is-visible');
            $errorField.textContent = newsletterAlerts[2];
          } else {
            document.querySelector('.newsletter-success').classList.add('is-visible');
            $errorField.textContent = newsletterAlerts[0];
          }
        }).catch(function (error) {
          console.log(error);
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();