"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.About = function About() {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $tabHeaders = document.querySelectorAll('[data-tab-headers]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($tabHeaders) {
      $tabHeaders.forEach(function ($tabHeader) {
        $tabHeader.addEventListener('click', function (e) {
          $tabHeader.closest("[data-main-tab]").classList.toggle('is-open');
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();