"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Header = function Header() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Globals elements
   * @private
   */
  var $header = document.querySelector('.header');
  var $mobileMenu = document.querySelector('.mobile-menu');
  var $body = document.querySelector('body');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($header && $mobileMenu) {
      var $headerMobileMenu = $header.querySelector('.header-mobile-menu');
      var $headerMobileMenuClose = $mobileMenu.querySelector('.mobile-menu-close');
      if ($headerMobileMenu) {
        $headerMobileMenu.addEventListener('click', function () {
          $header.classList.toggle('is-hidden');
          $mobileMenu.classList.toggle('is-active');
          $body.classList.toggle('is-locked');
        });
      }
      if ($headerMobileMenuClose) {
        $headerMobileMenuClose.addEventListener('click', function () {
          $header.classList.toggle('is-hidden');
          $mobileMenu.classList.toggle('is-active');
          $body.classList.toggle('is-locked');
        });
      }
    }
    if ($header) {
      var headerLogo = $header.querySelector('.header-logo');
      var headerRight = $header.querySelector('.header-right');
      if (headerLogo && headerRight) {
        var headerRightWidth = headerRight.offsetWidth;
        if (window.innerWidth > 1100) {
          headerRightWidth = headerRight.offsetWidth;
          headerLogo.style.width = "".concat(headerRightWidth, "px");
        } else {
          headerLogo.style.width = 'auto';
        }
        window.addEventListener('resize', function () {
          if (window.innerWidth > 1100) {
            headerRightWidth = headerRight.offsetWidth;
            headerLogo.style.width = "".concat(headerRightWidth, "px");
          } else {
            headerLogo.style.width = 'auto';
          }
        });
      }
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();