"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Sliders = function Sliders() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables
   * @private
   */
  var $sliders = document.querySelectorAll('[data-slider]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    $sliders.forEach(function (slider) {
      var noNav = slider.classList.contains('slider-no-nav');
      var noDots = slider.classList.contains('slider-no-dots');
      var noImage = slider.classList.contains('slider-no-image');
      var cellAlign = slider.classList.contains('slider-cell-align') ? 'center' : 'left';
      var flkty = new Flickity(slider, {
        cellAlign: cellAlign,
        contain: true,
        prevNextButtons: !noNav,
        pageDots: !noDots,
        fade: true,
        imagesLoaded: !noImage,
        arrowShape: 'M37.5005 82.7014L35.7327 80.9336L8.64937 53.8503L6.8816 52.0825L8.64937 50.3147L35.7327 23.2314L37.5005 21.4636L41.036 24.9992L39.2682 26.7669L16.4527 49.5825L89.5838 49.5825L92.0838 49.5825L92.0838 54.5825L89.5838 54.5825L16.4527 54.5825L39.2682 77.3981L41.036 79.1658L37.5005 82.7014Z'
      });
      if (slider.classList.contains('has-custom-nav')) {
        var $nearestNav = document.querySelector('.nav-for-' + slider.dataset.sliderId);
        if ($nearestNav) {
          // previous
          var previousButton = $nearestNav.querySelector('.custom-nav-arrow-left');
          previousButton.addEventListener('click', function () {
            flkty.previous();
          });
          // next
          var nextButton = $nearestNav.querySelector('.custom-nav-arrow-right');
          nextButton.addEventListener('click', function () {
            flkty.next();
          });
        }
      }
    });
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();