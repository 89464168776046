"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Misc = function Misc() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables
   * @private
   */
  var $selectCities = document.querySelector('.select-cities');
  var $promoBanner = document.querySelector('.promoBanner:not(.is-fixed)');
  var $main = document.querySelector('.main');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Add .big class for animation on mobile and remove it on desktop
    if ($selectCities) {
      var button = $selectCities.querySelector('.select-cities-button');
      addOrRemoveClassOnMobile(button, 'big');
      window.addEventListener('resize', function () {
        addOrRemoveClassOnMobile(button, 'big');
      });
    }

    // Once the header is scroll until his height, move the promo banner to the top
    if ($promoBanner) {
      var promoBannerHeight = $promoBanner.offsetHeight;
      $main.style.paddingTop = "".concat(80 + promoBannerHeight, "px");
      window.addEventListener('resize', function () {
        promoBannerHeight = $promoBanner.offsetHeight;
        $main.style.paddingTop = "".concat(80 + promoBannerHeight, "px");
      });
    }
    return true;
  };

  /**
   * Add or remove class depends on window width
   * @private
   */
  var addOrRemoveClassOnMobile = function addOrRemoveClassOnMobile(element, elementClass) {
    if (window.innerWidth < 768) {
      element.classList.add(elementClass);
    } else {
      element.classList.remove(elementClass);
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();